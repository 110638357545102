/* all  the global styles should be place here */

/* @import url('https://fonts.cdnfonts.com/css/helvetica-neue-lt-pro-2'); */

@import "bulma/css/bulma.css";

body {
  margin: 0;
  /* Reset margin to ensure the color covers the entire page */
  padding: 0;
  /* Reset padding for the same reason */
}

.is-gapless {
  display: none;
}

.image-uploader {
  position: relative;
  border: 6px solid #ffffff;
  overflow: hidden;
  height: 127px;
  width: 127px;
  border-radius: 65px;
  display: flex;
  align-items: center;
  box-shadow: 3px 11px 8px #00000029;
}

.image-uploader:hover::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.image-uploader:hover::after :hover {
  opacity: 0.8;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-button {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}

#sidenav-container {
  display: none !important;
}

/* PdfPreview.css */

.pdf-preview {
  display: flex;
  width: 100%;
  height: 80%;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
}
.rpv-core__page-layer {
  margin: auto;
}
.loading {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 18px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.MuiCircularProgress-root {
  color: red !important;
  height: 30px;
  width: 30px;
}

.search-url {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1023px) {
  .search-url {
    width: 400px;
  }
}

.user-profile-static-chat {
  width: 93px;
  height: 93px;
}

@media screen and (max-width: 1440px) {
  .user-profile-static-chat {
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 1023px) {
  .user-profile-static-chat {
    width: 50px;
    height: 50px;
  }
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica\ LT\ Std\ Roman.otf");
}

.css-liqizh-MuiGrid-root {
  max-width: 100%;
}
.css-rje4ir-MuiGrid-root {
  /* width: "300px"; */
}

.pdf-view {
  display: flex;
  flex-direction: column-reverse;
}

/* @media only screen and (max-width: 600px) {
  .category-container {
    background: red;
  }
} */

@media only screen and (min-width: 600px) {
  .category-container {
    display: flex;

    flex-direction: column;
  }
  .image-uploader-container {
    position: relative;
  }
}
@media only screen and (min-width: 768px) {
  .category-container {
    display: flex;

    flex-direction: column;
  }
  .image-uploader-container {
    position: relative;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .category-container {
    display: flex;
    flex-direction: row;
  }
  .image-uploader-container {
    margin-left: 15%;
  }
}

.image-uploader-container {
  display: flex;
  align-items: center;
  top: 9%;
}
.css-jiyrgy {
  background-color: #ffffff;
}

.category-container .MuiGrid-item {
  max-width: 100% !important;
}

.category-container .MuiBox-root > .MuiGrid-container {
  display: block !important;
  padding-right: 30px;
}

.mobile-menu-button {
  position: absolute;
  top: -5px;
  left: 10px;
  z-index: 11;
  color: #000;
}
.introduction-upper-card {
  width: 100%;
  height: auto;
  padding-right: 20px;
}
.introduction-upper-card p,
.introduction-upper-card span {
  height: auto;
}
@media (max-width: 900.98px) {
  .MuiTypography-welcomeUser {
    display: none;
  }
  main.MuiBox-root + .MuiBox-root {
    margin-left: 0px !important;
    padding-left: 20px !important;
  }
  .MuiPaper-elevation {
    margin-right: 0 !important;
    width: 100%;
  }
  .static-chat-screen {
    margin-left: 0 !important;
  }
  .static-chat-screen p {
    font-size: 18px !important;
  }

  header.MuiPaper-elevation {
    height: auto;
    width: 100%;
  }
  main {
    width: 100% !important;
  }
  .introduction-upper-card {
    width: 100%;
    height: auto;
    margin-left: 0 !important;
  }
  .introduction-upper-card .MuiBox-root {
    width: 100%;
  }
  .introduction-upper-card .MuiBox-root p {
    height: auto;
  }

  .introduction-upper-card .MuiStack-root {
    width: 100%;
  }
  .image-uploader {
    width: 127px;
  }
  .css-3f6ufr-MuiGrid-root > .MuiGrid-item {
    padding-left: 10px !important;
  }
  .css-0 .css-1m5m3m1 {
    left: 1px;
    column-gap: 1px;
  }
  /* .css-1bhom8t-MuiModal-root-MuiDrawer-root {
    background: none !important;
  } */
}
.css-1pwlt8w {
  background: none !important;
}
.pdf-btn-img-container {
  width: 25px !important;
}
.css-14c3xcb-MuiModal-root-MuiDrawer-root {
  background: none !important;
}
@media (max-width: 430px) {
  .image-uploader {
    width: 208px;
  }
}
/* scrollbar custom design */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #c20000; /* Adjust the color to your preference */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
/* login page  */
.login-form-container {
  width: 462px;
  height: 449px;
  background: #e8112a;
  padding: 10px 28.71px 0 28.71px;
  box-shadow: 40px 40px;
  margin-bottom: 150px; /* Apply margin bottom 150px for extra small screens and 0 for extra large screens */
  @media (max-width: 600px) {
    /* small screens */
    height: 500px;
  }
}

.responsive-image {
  cursor: pointer;
  width: 190px;
  @media (max-width: 600px) {
    /* small screens */
    width: 90px;
  }
  @media (min-width: 600px) {
    /* Medium screens */
    width: 90px;
  }

  @media (min-width: 960px) {
    /* Large screens */
    width: 190px;
  }
}
/* ToolGuide */
.tool-guide {
  display: flex;
}
@media screen and (max-width: 1700px) {
  .tool-guide {
    flex-direction: column;
  }
  .main-content {
    margin: 0px !important;
  }
  .button-container {
    flex-direction: column;
  }
  .description-font {
    font-size: 10px !important;
  }
  .intro-paragraph-with-img {
    flex-direction: column;
  }
  .intro-img-in-paragraph {
    width: 70px;
    margin: auto;
  }
  .objective-body {
    flex-direction: column;
  }
  .principle-container {
    flex-direction: column;
  }
  .header-font {
    font-size: 12px !important;
  }
  .glossary-body {
    flex-direction: column;
  }
  .glossary-paragraph {
    padding: 20px;
  }
  .terms-of-use-paragraph {
    flex-direction: column;
  }
  .terms-of-use-paragraph p {
    padding: 20px;
  }
  .dropzone-image {
    display: none;
  }
  .dropzone {
    margin: 20px 15px !important;
  }
  .input-container {
    flex-direction: column;
  }
  .input-group {
    width: 80% !important;
    margin: auto;
  }
  .container-header {
    flex-direction: column;
    padding: 20px;
  }
  .attachment-img {
    margin: 0px !important;
  }
  .comment-header {
    font-size: 8px !important;
    margin: 0px !important;
  }
  .comment-body {
    margin: 0px !important;
  }
  .comment-body > div:first-child {
    padding-left: 40px !important;
  }
  .left-content {
    padding-left: 0px !important;
  }
  .data-history-inputfields {
    flex-direction: column;
  }
  .history-table-body {
    font-size: 8px !important;
  }
  .history-table-header {
    font-size: 8px !important;
  }
}
.description-font {
  font-size: 20px;
}
.header-font {
  font-size: 18px;
}
.sidebar {
  /* width: 600px;  */
  padding-left: 40px;
}
.container-header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid rgb(189, 189, 189);
  padding-bottom: 20px;
}
.principle-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: 1px solid rgb(189, 189, 189);
  padding-bottom: 10px;
}
.term-of-use-paragraph {
  padding-right: 40px;
}
.glossary-paragraph {
  padding: 0px 40px 20px 20px;
}
.main-content {
  flex-grow: 1;
  margin: 0px 100px 0px 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.list-text {
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.selected-item {
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
}
.list-image {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;
}
.tool-guide_button-container {
  width: 100%;
  text-align: right;
}

.tool-guide_button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 60px;
}
.comment-section-body .comment-header {
  margin: 50px 70px 0px 70px;
  border: 1px solid;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(240, 237, 237);
  font-weight: bolder;
  font-size: 20px;
}
.comment-body {
  display: flex;
  margin: 0px 70px;
  border-bottom: 1px solid lightgray;
  padding: 30px 0px 10px 0px;
}

/* CompanyINfo */
.input-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the gap as needed */
}

.input-group {
  display: flex;
  flex-direction: column;
}

.selected-button {
  padding: 10px 60px;
  height: 55px;
  font-size: 22px;
  border-radius: 10px;
  border: none;
  background-color: #31c8a5;
  color: white;
  cursor: pointer;
}

.unselected-button {
  padding: 10px 60px;
  height: 55px;
  font-size: 22px;
  border-radius: 10px;
  border: none;
  background-color: white;
  color: black;
  cursor: pointer;
}
/* Company Info */
.company-info-inputs {
  height: 50px;
  font-size: 18px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: none;
  background-color: #f6f7f7;
  padding: 8px;
}
/* History data */
.history-table-header {
  margin: 5px 20px;
  border: 1px solid;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(240, 237, 237);
  font-weight: bolder;
  font-size: 20px;
}
.history-table-body {
  margin: 5px 20px;
  border-bottom: 1px solid grey;
  padding: 20px;
  font-size: 20px;
}
/* Principle's question */
.question-modal-save-button {
  background-color: black !important;
  color: white !important;
  padding: 5px 20px !important;
  border: none !important;
  border-radius: 25px !important;
  cursor: pointer !important;
  text-transform: uppercase !important;
  margin-left: 10px !important;
}
.question-modal-cancel-button {
  padding: 5px 20px !important;
  border: 2px solid !important;
  border-radius: 25px !important;
  cursor: pointer !important;
  text-transform: uppercase !important;
}
.hr {
  margin: 0.5rem 0 !important;
}
/* History data */
/* .history-data-container {
  max-height: 65vh;
  overflow-y: auto;
} */
.pagination-UI {
  display: flex;
  justify-content: center;
}
.history-table-body {
  font-size: 15px;
}
/* Company Info */
.document-upload-button-container {
  width: 100%;
  text-align: right;
}

.document-upload-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 60px;
}
/* Intro page */
.carousel-slider {
  height: 500px;
}
.thumbs-wrapper {
  display: none;
}
.intro-img-in-paragraph {
  margin: 0px 25px 25px 25px;
  @media (max-width: 600px) {
    /* small screens */
    width: 50px;
    margin: 10px;
  }
}
.intro-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48vh; /* Adjust this as needed */
  width: 50vw; /* Adjust this as needed */
  overflow: hidden;
  background-color: #f0f0f0;
}
.intro-pic img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensures the image scales and fits within the container */
}
.carousel .slide iframe {
  margin: 0px !important;
}
.carousel .control-dots .dot {
  height: 14px !important;
  width: 14px !important;
  border: 1px solid grey;
}
.dropzone-parent-container {
  margin: 0 10%;
}
